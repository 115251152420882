import { Injectable, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http'
import { Patient } from '../interfaces/ipatient';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment'
import { ICatalog } from '../interfaces/icatalog';
import { TreatmentConcept } from '../interfaces/treatment-concept';
import {map} from 'rxjs/operators'
import { TreatmentPromo } from '../interfaces/treatment-promo';

@Injectable({
  providedIn: 'root'
})


export class CatalogService implements OnInit{
    private apiBase:string;
    public catalognName:string;
   
    constructor (private http:HttpClient){
        this.apiBase= environment.httpServices.patientsUrl;        
    }

    ngOnInit() {
        
    }
    public get():Observable<any[]>{
        return this.http.get<any[]>(this.apiBase+'/api/'+ this.catalognName);
    }

    public post(catalog:ICatalog):Observable<any>{
        return this.http.post(this.apiBase+'/api/'+this.catalognName,catalog);
    }

    public getByPromo(promo:string):Observable<any[]>{
        return this.http.get<any[]>(this.apiBase+'/api/TreatmentConcepts/bypromocode/'+promo)
    }

    public getBasePriceList():Observable<TreatmentConcept[]>{
        return this.http.get<TreatmentConcept[]>(this.apiBase+'/api/TreatmentConcepts/baselist')
    }

    public getListType():Observable<any[]>{
        return this.http.get<any[]>(this.apiBase+'/api/TreatmentConcepts/listtype')
    }

    public getValidatePromo(pc:string):Observable<any>{
        return this.http.get<Observable<any>>(this.apiBase+'/api/TreatmentConcepts/validatepromo?promoCode='+pc)
    }

    public getTreatmentConcepts(promoCode:string):Observable<any>{
        return this.http.get<any>(this.apiBase+'/api/TreatmentConcepts/bypromocode/'+promoCode)
    }

    public saveTreatmentConcept(tc: TreatmentConcept):Observable<any>{
        return this.http.post<any>(this.apiBase+'/api/TreatmentConcepts',tc)
    }

    public updateTreatmentConcept(tc:TreatmentConcept ){
        return this.http.put<any>(this.apiBase+'/api/TreatmentConcepts/'+tc.Id.toString(),tc)
    }

    public validateProcCode(procCode:string, promoCode:string):Observable<string>{
        return this.http.get<any>(this.apiBase+'/api/TreatmentConcepts/validateprocedurecode?code='+procCode+'&promo='+promoCode).pipe(
            map((r)=>{
                return r.message
            })
        )
    }
    public postPromotion(promo:TreatmentPromo):Observable<any>{
        let request ={
            promoHeader:promo
        }
        return this.http.post(this.apiBase+'/api/TreatmentConcepts/promotion',request)
    }

    public updatePromotion(promo:TreatmentPromo):Observable<any>{
        let request={
            promoHeader:promo
        }
        return this.http.put(this.apiBase+'/api/TreatmentConcepts/promotion',request)
    }
    
    public getClinicLocations():Observable<any[]>{
        return this.http.get<any[]>(this.apiBase+'/api/cliniclocations/')
    }

    public getClinicLocationById(id): Observable<any>{
        return this.http.get<any>(this.apiBase + '/api/cliniclocations/' + id.toString())
    }
}