import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './shared/inmemory-db/inmemory-db.service';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './shared/services/auth.service';
import { LoadingScreenComponent } from './views/loading-screen/loading-screen.component';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { httpInterceptorsProviders } from './shared/interceptor-index';
import { AuthConfigModule } from './auth-config.module';
import { IdsAuthService } from './shared/services/ids.auth.service';
import { AuthWellKnownEndpoints, ConfigResult, OidcConfigService, OidcSecurityService, OpenIdConfiguration } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';
import { env } from 'process';


const oidc_configuration = 'assets/auth.clientConfiguration.json';
// if your config is on server side
// const oidc_configuration = ${window.location.origin}/api/ClientAppSettings
 
export function loadConfig(oidcConfigService: OidcConfigService) {
    return () => oidcConfigService.load(oidc_configuration);
}


@NgModule({
  declarations: [
    AppComponent,
    LoadingScreenComponent,    
  ],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    InMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true }),
    AppRoutingModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      primaryColour: '#730099',
      secondaryColour: '#730099',
      tertiaryColour: '#730099',
      backdropBackgroundColour: 'rgba(0,0,0,0.08)', 
    }),
    AuthConfigModule
  ],
  providers: [
    AuthService, 
    IdsAuthService,
    httpInterceptorsProviders,
    OidcConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: loadConfig,
            deps: [OidcConfigService],
            multi: true,
        },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(private oidcSecurityService: OidcSecurityService, private oidcConfigService: OidcConfigService) {
    this.oidcConfigService.onConfigurationLoaded.subscribe((configResult: ConfigResult) => {
    
      const config: OpenIdConfiguration = {
        stsServer: environment.oidcSettings.stsServer,
        redirect_url: environment.oidcSettings.redirect_url,
        client_id: environment.oidcSettings.client_id,
        response_type: environment.oidcSettings.response_type, 
        scope: environment.oidcSettings.scope,
        post_logout_redirect_uri: environment.oidcSettings.post_logout_redirect_uri,
        start_checksession: environment.oidcSettings.start_checksession,
        silent_renew: environment.oidcSettings.silent_renew,
        log_console_warning_active: environment.oidcSettings.log_console_warning_active,
        log_console_debug_active: environment.oidcSettings.log_console_debug_active,
        max_id_token_iat_offset_allowed_in_seconds: environment.oidcSettings.max_id_token_iat_offset_allowed_in_seconds,
    };

    const authWellKnownEndpoints: AuthWellKnownEndpoints = {
        issuer:  environment.oidcSettings.wellKnownSettings.issuer,
        authorization_endpoint: environment.oidcSettings.wellKnownSettings.authorization_endpoint,
        token_endpoint: environment.oidcSettings.wellKnownSettings.token_endpoint,
        userinfo_endpoint: environment.oidcSettings.wellKnownSettings.userinfo_endpoint,
        end_session_endpoint: environment.oidcSettings.wellKnownSettings.end_session_endpoint,
        check_session_iframe: environment.oidcSettings.wellKnownSettings.check_session_iframe,
        revocation_endpoint: environment.oidcSettings.wellKnownSettings.revocation_endpoint,
        introspection_endpoint: environment.oidcSettings.wellKnownSettings.introspection_endpoint,
        jwks_uri: environment.oidcSettings.wellKnownSettings.jwks_uri
    };

    this.oidcSecurityService.setupModule(config, authWellKnownEndpoints);

    });
}

}
