import { HttpClient } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class IdsAuthService {

   private apiBase ='';
   isLoggedIn = false;
  constructor(public oidcSecurityService: OidcSecurityService, private httpCLient: HttpClient) { 
      this.apiBase =environment.oidcSettings.stsServer +'/api'

  }

  login() {
    this.oidcSecurityService.authorize();
  }

  logout() {
    this.oidcSecurityService.logoff();
  }

  getToken(){
    const token = this.oidcSecurityService.getToken();
    console.log(token)
  }

  getIdToken(){
    const id_token = this.oidcSecurityService.getIdToken(); 
    console.log("id token", id_token);
  }

  isAuthenticated(): Observable<boolean>{
    return this.oidcSecurityService.getIsAuthorized();
  }

  
  getUserClaims(): Observable<any> {
    return this.getUserData()
    .pipe(
        tap(res=>{
           console.log(res);
        })
        ,switchMap((userdata)=>{
          return this.retrieveUserDetails(userdata.preferred_username)
          
        })
    );
  }
  
  getUserData():Observable<any>{
    return this.oidcSecurityService.getUserData()
  }

  registerUser(userName:string,
       email:string,
       password:string,
       phoneNumber:string,
       claims: any
      ):Observable<any>{
    
    var registerUserModel  ={
        userName: userName, 
        email: email, 
        password: password, 
        phoneNumber: phoneNumber,
        claims: claims
    };   
    return this.httpCLient.post(this.apiBase+ '/users/register', registerUserModel)
  }

  updateUser(userName:string,
    email:string,
    password:string,
    phoneNumber:string,
    claims: any
   ):Observable<any>{
 
 var registerUserModel  ={
     userName: userName, 
     email: email, 
     password: password, 
     phoneNumber: phoneNumber,
     claims: claims
 };   
 return this.httpCLient.put(this.apiBase+ '/users/update', registerUserModel)
}

  listUsers(){
    return this.httpCLient.get<any>(this.apiBase +'/users')
  }

  lockUnlockAccount(user:string , isLocked :string){
    var model ={
      userName:user
    };
    let apiEndpoint  =''
   
    if(isLocked=="Si"){
      apiEndpoint ='/users/unlock';
    }
    else{
      apiEndpoint='/users/lock'
    }
    return this.httpCLient.put(this.apiBase +apiEndpoint,model)
  }

  retrieveUserDetails(username):any{
    return this.httpCLient.get(this.apiBase+ '/users/byname?username='+ username);
  }

  updatePassword (userName:string , password:string ){
    let model  ={
      username: userName, 
      password: password
    }; 

    return this.httpCLient.put(this.apiBase + '/users/resetpassword', model);

  }

  getUsersInGroup(groupName:string):Observable<any>{
    return this.httpCLient.get(this.apiBase + '/users/bygroupname?groupname='+ groupName);
  }

  getCurrentUser(): Observable<any>
  {
    return this.oidcSecurityService.getUserData();
  }

}