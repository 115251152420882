import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AuthModule, OidcConfigService } from 'angular-auth-oidc-client';

@NgModule({
    imports: [AuthModule.forRoot()],
    exports: [AuthModule],
    providers: [
       
    ],
})
export class AuthConfigModule {}
