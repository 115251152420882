import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';
import { error } from 'protractor';
import { Observable, of } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { IdsAuthService } from './ids.auth.service';

@Injectable({
  providedIn: 'root'
})
export class PatientGuard implements CanActivate{
  
  constructor(private auth:IdsAuthService,  private router:Router){
  
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.auth.getUserClaims()
    .pipe(
      switchMap(res=>{
        if(res.claims.puesto == undefined){
          //alert('No tienes acceso para entrar a estra seccion del sistema');
          this.router.navigate(['others/forbidden'])
          return of( false);
       }
         if(res.claims.puesto=="admin" || res.claims.puesto =="doctor" ||res.claims.puesto =="especialista" || res.claims.puesto=="asistente")
         return of(true);
       else {
        //alert('No tienes acceso para entrar a estra seccion del sistema');
        this.router.navigate(['others/forbidden'])
        return of(false);
       }
      })
    )
  }
}

/*  GUARDA PARA LA RUTA DE ADMIN  */
@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate{
  
  constructor(private auth:IdsAuthService, private router:Router){
  
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.auth.getUserClaims()
    .pipe(
      switchMap(res=>{
        if(res.claims.puesto == undefined){
          //alert('No tienes acceso para entrar a estra seccion del sistema');
          this.router.navigate(['others/forbidden'])
          return of( false);
       }
         if(res.claims.puesto=="admin")
         return of(true);
       else {
          //alert('No tienes acceso para entrar a estra seccion del sistema');
          this.router.navigate(['others/forbidden'])
          return of(false);
       }
      })
    )
  }
}

@Injectable({
  providedIn: 'root'
})
export class FinGuard implements CanActivate{
  
  constructor(private auth:IdsAuthService, private router:Router){
  
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.auth.getUserClaims()
    .pipe(
      switchMap(res=>{
        if(res.claims.puesto == undefined){
          //alert('No tienes acceso para entrar a estra seccion del sistema');
          this.router.navigate(['others/forbidden'])
          return of( false);
       }
         if(res.claims.puesto=="admin" )
         return of(true);
       else {
          //alert('No tienes acceso para entrar a estra seccion del sistema');
          this.router.navigate(['others/forbidden'])
          return of(false);
       }
      })
    )
  }
}