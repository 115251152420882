import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpResponse, HttpHandler, HttpRequest, HttpHeaders, HttpEvent } from "@angular/common/http";
import { AuthService } from "./services/auth.service";
import { fromPromise } from "rxjs/observable/fromPromise";
import { map, tap, take, switchMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log("Auth interceptor")
        return next.handle(request);
        // return this.authService.getAccessToken()
        // .pipe(
        //   switchMap((newToken: string) => {        
        //     return next.handle(this.addToken(request, newToken));
        //   })
        // );
    }

    addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        // HttpHeader object immutable - copy values
        const headerSettings: {[name: string]: string | string[]; } = {};
        let changedRequest = req;
        for (const key of req.headers.keys()) {
          headerSettings[key] = req.headers.getAll(key);
        }
        if (token) {
            //console.log('TOKENN')
            //console.log(token)
          headerSettings['Authorization'] =  token;
          
         }
      const newHeader = new HttpHeaders(headerSettings);
      //    ;
         changedRequest = req.clone({
           headers: newHeader});
  
      return changedRequest;
    }
}