import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { env } from 'process';
import { CatalogService } from '../../services/http/catalog.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  version=""
  clinicId:number
  clinicName = ""; 
  constructor(private catalogService:CatalogService) { }

  ngOnInit() {
    this.version=' Version: '+  environment.appversion
    this.clinicId = environment.clinicLocationId
    this.loadClinicLocations();
  }

  loadClinicLocations(){
    this.catalogService.getClinicLocationById(environment.clinicLocationId)
   .subscribe((res)=>{
      console.log("clinica obtenida es " + res.description)
      this.clinicName = res.description; 
   })
 }

  public get instanceName():String {
    return this.clinicName
  }

}
