import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { map, tap, catchError, filter, first } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import Amplify, { Auth } from 'aws-amplify';
import { environment } from '../../../environments/environment';
import { stringType } from 'aws-sdk/clients/iam';
import { CognitoIdentityServiceProvider } from 'aws-sdk'
import * as AWS from 'aws-sdk';
import { ThrowStmt } from '@angular/compiler';
import { from, observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

// Comentario X
@Injectable()
export class AuthService {

  public loggedIn: BehaviorSubject<boolean>;
  private poolId:string;
  private awsRegion:string; 
  private awsAccessKeyId:string;
  private awsSecretKeyId:string;
  userIsAuthenticated: boolean;

  constructor(
    private router: Router,
    private http:HttpClient
  ) {
       //Amplify.configure(environment.amplify);
    this.loggedIn = new BehaviorSubject<boolean>(false);
   // this.configAws();
    //AWS.config.update({ region: 'us-east-2', 'accessKeyId': 'AKIAYMXQFZQ6EW66FD2N', 'secretAccessKey': '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' });

  }


  public changePassword(oldPass: string, newPass: string): Observable<any> {

    let errorDesc: String;

    return fromPromise(Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, oldPass, newPass);
      }));
    // return fromPromise(Auth.currentAuthenticatedUser()
    // .then(user => {
    //     return Auth.changePassword(user,oldPass ,newPass );
    // })
    // .then(data => console.log(data))
    // .catch(err => console.log(err)));
  }

  public deleteUser(userName: string): string {

    //AWS.config.update({ region: 'us-east-2', 'accessKeyId': 'AKIAYMXQFZQ6EW66FD2N', 'secretAccessKey': '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' });
    var params = {
      UserPoolId:  this.poolId,
      Username: userName,
    };

    var cognitoProvider = new CognitoIdentityServiceProvider();
    cognitoProvider.adminDeleteUser(params, (err, data) => {
      if (err) {
        console.log("error eliminado el usuario pedorro");
        return "Usuario eliminado correctamente";
      }
      if (data) {
        console.log("usuario eliminado correctamente");
        return "Usuario eliminado correctamente";
      }
    });
    return "";
  }

  public deleteUserAsync(username: string): Promise<any> {

    return new Promise((resolve, reject) => {
      // set request parameters
      var params = {
        UserPoolId:  this.poolId,
        Username: username,
      };
      var cognitoProvider = new CognitoIdentityServiceProvider();
      cognitoProvider.adminDeleteUser(params, (err, data) => {
        if (err) {
          console.log("error eliminado el usuario pedorro");
          reject(err);
        }
        if (data) {
          console.log("usuario eliminado correctamente");
          resolve(data);
        }
      });
    })
  }


  public getUserList(): Promise<any> {

    var congnitoProvider = new CognitoIdentityServiceProvider();
    var params = {
      UserPoolId:  this.poolId
    };

    return new Promise((resolve, reject) => {
      congnitoProvider.listUsers(params, (error, data) => {
        if (error) {
          console.log("Error al obtener lista de usuarios:" + error);
          reject(error);
        }
        if (data) {
          console.log("Usuarios obtenidos correctamente");
          resolve(data);
        }
      });
    });
  }

  public async getCurrentUserAsync(): Promise<any> {
    try {
      const res = await Auth.currentUserPoolUser();
      console.log('Usuario logeado',res)
      return res;
    } catch (err) {
      console.error(err);
      return null;
    }
  }
  public getCurrentUser(): Observable<any> {
    return fromPromise(Auth.currentUserPoolUser());
  }
  public completeNewPassword(user: any, newPassword: string): Observable<any> {
    return fromPromise(Auth.completeNewPassword(
      user,               // the Cognito User Object
      newPassword, {}
    ));
  }
  public getAccessToken(): Observable<string> {
    let token: string;

    return fromPromise(
      Auth.currentSession()
    ).pipe(
      filter(value => value !== null && value !== undefined),

      map(
        (session) => session.getIdToken().getJwtToken()
      ),
      first()
    );



  }
  /** signup */
  public signUp(userName: string, email: string, password: string, fullName: string, address: string, jobRole: string, phoneNumber: string): Observable<any> {
    return fromPromise(Auth.signUp({
      username: userName,
      password: password,
      attributes: {
        email: email,
        'custom:FullName': fullName,
        'custom:Address': address,
        'custom:PhoneNumber': phoneNumber,
        'custom:JobPosition': jobRole
      }
    }));
  }

  /** confirm code */
  public confirmSignUp(email, code): Observable<any> {
    return fromPromise(Auth.confirmSignUp(email, code));
  }


  public signIn(email, password): Observable<any> {

    var model  ={
      UsernName: email, 
      password: password,
      rememberMe:false
    }; 

    return this.http.post("https://sso.adminmasdental.com/api/Account/login",model);
    

    // return fromPromise(Auth.signIn(email, password))
    //   .pipe(
    //     tap(() => this.loggedIn.next(true)),
    //     catchError(error => {

    //       this.loggedIn.next(false);
    //       return of(false);
    //     })
    //   );
  }

  public getGroupsForCurrentUser():Observable<any>{
    return fromPromise(this.getCurrentUser())
    .pipe(
      map((r)=>{
        fromPromise(this.getGroupsForUser(r.username))
        .subscribe((data)=>{
          return data
        })        
      }),
      tap((x)=>{
        console.log(x)
      })
    )
  }


  public isAuthenticated(): Observable<boolean> {
    return of(true);
    //TODO: Check if autenticated
    // return fromPromise(Auth.currentAuthenticatedUser())
    //   .pipe(
    //     map(result => {
    //       console.debug('Usuario autenticado')
    //       this.loggedIn.next(true);
    //       return true;
    //     }),
    //     catchError(error => {
    //       //  debugger;
    //       console.debug('Usuario no autenticado')
    //       this.loggedIn.next(false);
    //       throw error
    //     })
    //   );
  }

  
  
  



  /** signout */
  public signOut() {
    // clear sessions values
    sessionStorage.clear()
    return fromPromise(Auth.signOut())
      .subscribe(
        result => {
          this.loggedIn.next(false);
          this.userIsAuthenticated= false
          this.router.navigate(['sessions/signin']);
        },
        error => console.log(error)
      );
  }

  public getUserProfiles(): Promise<any> {
    return new Promise((resolve, reject) => {

      var congnitoProvider = new CognitoIdentityServiceProvider();
      var params = {
        UserPoolId:  this.poolId
      };

      congnitoProvider.listGroups(params,(err,data)=>{
        if(err)
        {
          console.log("Error al obtener los grupos para usuarios");
          reject(err);
        }

        if(data){
          resolve(data);
        }
      })
    });
  }

  public getGroupsForUser(username:string): Promise<any>{
      return new Promise((resolve,reject)=>{
          var params={
            UserPoolId:   this.poolId, /* required */
            Username: username, /* required */
          };

          var cognitoProvider= new CognitoIdentityServiceProvider();

          cognitoProvider.adminListGroupsForUser(params,(err,data)=>{
            if(err){
              console.log("Error al obtener los grupos para el usuario");
              reject(err);
            }

            if(data){
              resolve(data);
            }
          })
      });
  }

  public deleteGroupForUser(username:string, groupId:string):Promise<any>{

    return new Promise((resolve,reject)=>{
      var params = {
        GroupName: groupId, /* required */
        UserPoolId:  this.poolId, /* required */
        Username: username /* required */
      };

      var cognitoProvider= new CognitoIdentityServiceProvider();
      
      cognitoProvider.adminRemoveUserFromGroup(params,(err,data)=>{
        if(err){
          console.log("Error al remover usuario del grupo");
          reject(err);
        }
        if(data){
          resolve(data);
        }
      });
      
    })
  }

  public addUserToGroup(username:string, groupName:string ):Promise<any>{
    return new Promise((resolve,reject)=>{
      var params = {
        GroupName: groupName, /* required */
        UserPoolId:  this.poolId, /* required */
        Username: username /* required */
      };

      var cognitoProvider= new CognitoIdentityServiceProvider();

      cognitoProvider.adminAddUserToGroup(params, (err,data)=>{
        if(data){
          resolve(data);
        }

        if(err){
          console.log("Error al agregar usuario al grupo");
          reject(err);
        }
      })
      
    })
  }

  public setUserPassword(username:string, password:string):Promise<any>{
    return new Promise((resolve,reject)=>{
      var params = {
        Password: password, /* required */
        UserPoolId:  this.poolId,
        Username: username,
        Permanent: true /* El password nuevo sera permanente, el usuario debe cambiarlo una vez que inicie sesion*/ 
      };
      
      var cognitoProvider= new CognitoIdentityServiceProvider();
      
      cognitoProvider.adminSetUserPassword(params, function(err, data) {
        if(err){
          console.log("Error al cambiar password");
          reject(err);
        }
        else{
          console.log("password cambiado satisfactoriamente");
          resolve(data);
        }
        
      });



    })
  }

  public getUsersInGroup(groupName:string):Promise<any>{
    return new Promise((resolve,reject)=>{
      var params={
      GroupName: groupName, /* required */
      UserPoolId: this.poolId /* required */     
      };

      var cognitoProvider = new CognitoIdentityServiceProvider();

      cognitoProvider.listUsersInGroup(params,function(err,data){
        if(err) {console.log(err,err.stack); reject(err);}

        if(data){
          console.log("Usuarios por grupo obtenidos correctamente");
          resolve(data);
        }
      });
    });
  }

}// End of class