export const environment = {
  production: false,
  amplify: {
    Auth: {
      region: 'us-west-2',
      userPoolId: 'us-west-2_AFgsmdbzn',
      userPoolWebClientId:'4qmkod69d2sh4b2pgddh7e2isp' 
    }
  },
  accessKeyId:"AKIAYMXQFZQ6EW66FD2N",
  secretKeyId:"BHJfvqHYqagD2TIXXXICLQaMp0nmHKbxq787/TB9",
  httpServices:{
    patientsUrl:"https://adminmasdental.com/pacientes/",
    financialUrl:"https://adminmasdental.com/fin/",
    hcUrl:"https://adminmasdental.com/hc",
  },
  clinicLocationId:1,
  appversion:"2022.01.0713",
  oidcSettings:{
    stsServer: 'https://sso.adminmasdental.com',
        redirect_url: window.location.origin+'/#/dashboard/v1',
        client_id: 'MasDentalUI',
        response_type: 'code', 
        scope: 'openid profile',
        post_logout_redirect_uri: 'http://localhost:4200/#/sessions/signin',
        start_checksession: false,
        silent_renew: false,
        log_console_warning_active: true,
        log_console_debug_active: true,
        max_id_token_iat_offset_allowed_in_seconds: 10,

        wellKnownSettings :{
          issuer: 'https://sso.adminmasdental.com',
          authorization_endpoint: 'https://sso.adminmasdental.com/connect/authorize',
          token_endpoint: 'https://sso.adminmasdental.com/connect/token',
          userinfo_endpoint: 'https://sso.adminmasdental.com/connect/userinfo',
          end_session_endpoint: 'https://sso.adminmasdental.com/connect/endsession',
          check_session_iframe: 'https://sso.adminmasdental.com/connect/checksession',
          revocation_endpoint: 'https://sso.adminmasdental.com/connect/revocation',
          introspection_endpoint: 'https://sso.adminmasdental.com/connect/introspect',
          jwks_uri:'https://sso.adminmasdental.com/.well-known/openid-configuration/jwks'
        }
  }
};