
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import Amplify, { Auth } from 'aws-amplify';

if ( environment.production )
{
    enableProdMode();
}

Auth.configure({
   
    region:environment.amplify.Auth.region,
     identityPoolRegion:environment.amplify.Auth.region,
     userPoolId:environment.amplify.Auth.userPoolId,
     userPoolWebClientId:environment.amplify.Auth.userPoolWebClientId//,
   // authenticationFlowType: 'CUSTOM_AUTH'
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

