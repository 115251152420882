import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    type: string;       // Possible values: link/dropDown/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    active?: boolean;
}
export interface IChildItem {
    id?: string;
    parentId?: string;
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
    active?: boolean;
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

interface ISidebarState {
    sidenavOpen?: boolean;
    childnavOpen?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    public sidebarState: ISidebarState = {
        sidenavOpen: true,
        childnavOpen: false
    };
    constructor() {
    }

    defaultMenu: IMenuItem[] = [    
        {
            name: 'Pacientes',
            description: 'Consulta y registro de pacientes',
            type: 'dropDown',
            icon: 'i-Library',
            sub: [
                { icon: 'i-Bell', name: 'Consulta de pacientes', state: '/patients/list', type: 'link' },
                { icon: 'i-Bell', name: 'Registro nuevo paciente', state: '/patients/create', type: 'link' }                              
            ]
        },       
        {
            name: 'Finanzas',
            description: 'Modulo de finanzas',
            type: 'dropDown',
            icon: 'i-Financial',
            sub: [
                { icon: 'i-Search-on-Cloud', name: 'Cuentas por cobrar', state: '/cashaccounts/search', type: 'link' },
                { icon: 'i-Money1', name: 'Registro de pago', state: '/cashaccounts/payment', type: 'link' } , 
                { icon: 'i-Money1', name: 'Reporte de comisiones', state: '/comissions/report', type: 'link' },
                { icon: 'i-Money1', name: 'Configuracion de comisiones', state: '/comissions/config', type: 'link' }  ,    
                { icon: 'i-Money1', name: 'Reporte de caja', state: '/payments/cashreport', type: 'link' } , 
            ]
        },
        {
            name: 'Configuracion',
            description: 'Administracion de usuarios de la aplicacion',
            type: 'dropDown',
            icon: 'i-Computer-Secure',
            sub: [
                { icon: 'i-Email', name: 'Agregar Usuario', state: '/users/create', type: 'link' },
                // { icon: 'i-Speach-Bubble-3', name: 'Modificar contraseña', state: '/users/edit', type: 'link' },
                // { icon: 'i-Calendar', name: 'Eliminar Usuario', state: '/users/delete', type: 'link' },
                { icon: 'i-Calendar', name: 'Administrar usuarios', state: '/users/list', type: 'link' },
                { icon: 'i-Calculator-2', name: 'Lista de precios', state: '/pricelist/base', type: 'link' },
                { icon: 'i-Ticket', name: 'Configuracion de promociones', state: '/pricelist/promo', type: 'link' }        ,
                { icon: 'i-Dollar-Sign-2', name: 'Configuracion de tipo de cambio', state: '/currency/config', type: 'link' }  
            ]
        }            
    ];


   
    menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();

    // You can customize this method to supply different menu for
    // different user type.
    // publishNavigationChange(menuType: string) {
    //   switch (userType) {
    //     case 'admin':
    //       this.menuItems.next(this.adminMenu);
    //       break;
    //     case 'user':
    //       this.menuItems.next(this.userMenu);
    //       break;
    //     default:
    //       this.menuItems.next(this.defaultMenu);
    //   }
    // }
}
